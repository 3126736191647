import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Card, Grid, Typography } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <Card>
    <div className={styles.preview}>
      <Img alt="" fluid={article.heroImage.fluid} />
      <Grid container style={{ marginTop: 5 }}>
        <CalendarTodayIcon style={{ fontSize: '1rem', marginRight: 3 }}/>
        <Typography style={{ fontSize: '0.8rem' }}>{article.publishDate}</Typography>
      </Grid>
      <h3 className={styles.previewTitle}>
        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
      </h3>
      <div style={{ fontSize: '0.9rem' }}
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
      {article.tags &&
        article.tags.map(tag => (
          <Link to={`/tags/${tag}`} key={tag}>
            <span className={styles.tag}>
              {tag}
            </span>
          </Link>
        ))}
    </div>
  </Card>
)
